<template>
  <div class="help">
    <div class="help_l">
      <el-menu
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :default-openeds="openeds"
        unique-opened
      >
        <el-submenu
          v-for="(item, index) in classbig"
          :key="index"
          :index="item.classid.toString()"
        >
          <template slot="title">
            <!-- <i class="el-icon-location"></i> -->
            <span>{{ item.classname }}</span>
          </template>
          <el-menu-item
            v-for="itemsmall in classsmall"
            :key="itemsmall.id"
            :index="itemsmall.id.toString()"
            @click="classsmallclcik(itemsmall.id)"
            >{{ itemsmall.title }}</el-menu-item
          >
        </el-submenu>
      </el-menu>
    </div>
    <div class="help_r">
      <h3 class="help_rh3">{{ helpall.title }}</h3>
      <div v-html="helpall.pagetext"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classbig: [],
      classsmall: [],
      helpall: "",
      openeds: [],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      this.axioslistsmall(key);
    },
    handleClose(key, keyPath) {
      this.openeds=[];
      this.openeds.push(key);
      this.classsmall = [];
      //   console.log(key, keyPath);
    },
    axiosqq() {
      var that = this;
      this.$axios({
        url: "/api/getClassTopList",
        method: "post",
        data: {
          bclassid: "9",
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.classbig = res.data.result;
          that.$forceUpdate();
        }
      });
    },
    axioslistsmall(classid) {
      var that = this;
      this.classsmall = [];
      this.$axios({
        url: "/api/getHelpTitle",
        method: "post",
        data: {
          classid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.classsmall = res.data.result;
        }
      });
    },
    classsmallclcik(id) {
      var that = this;
      document.documentElement.scrollTop = 0;
      this.$axios({
        url: "/api/getHelpDetail",
        method: "post",
        data: {
          id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.handleClose();
          that.helpall = res.data.result;
        }
      });
    },
  },
  created() {
    var id = this.$route.query.data;
    var bid = this.$route.query.big;
    if (id != undefined) {
      this.openeds.push(bid);
      this.activebig = id;
      this.axiosqq();
      this.classsmallclcik(id);
      this.axioslistsmall(bid);
    } else {
      this.openeds.push("53");
      this.activebig = "18";
      this.axiosqq();
      this.classsmallclcik(18);
      this.axioslistsmall(53);
    }
  },
  watch: {
  },
};
</script>
<style>
@import "../assets/css/help.css";
</style>